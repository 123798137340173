/** @jsxImportSource @emotion/react */
import React, {ReactNode, useEffect, useState} from 'react';
import {css} from '@emotion/react';
import LeftChevronIcon from '../Icons/LeftChevronIcon';
import RightChevronIcon from '../Icons/RightChevronIcon';
import {theme} from '../theme';
import ZLogo from '../ZLogo';

const styles = {
  root: css`
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 600px;
      justify-content: center;
      align-items: center;
      overflow: hidden;
  `,
  carousel: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      overflow: hidden;
  `,
  content: css`
      width: 100%;
      height: 500px;
      align-items: center;
      justify-content: center;
      display: flex;

  `,
  buttonContainer: css`
      display: flex;
      justify-content: center;
      align-items: center;
  `,
  icon: css`
      color: ${theme.colors.frontZ};
      font-size: 50px;
  `,
  subtitle: css`
      font-size: 18px;
      color: #fff;
      padding-bottom: 10px;
  `,
  extrusion: css`
      width: 100%;
      height: 100%;
      position: relative;
      transform-style: preserve-3d;
      transition: transform 1s;
  `,
  stage: css`
      perspective: 1000px;
      display: flex;
      height: 100%;
      width: 100%;
      flex-direction: row;
      align-items: center;

  `,
  plane: css`
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;

      span {
          display: flex;
          flex-direction: row;
          align-items: center;
      }
  `,
  left: (hRotation: number, vRotation: number) => css`
      background-color: #304145;
      border: solid 1px ${theme.colors.backZ};
      color: white;
      font-size: 30px;
      align-items: center;
      justify-content: center;
      transform: rotateY(${hRotation}deg) rotateX(${vRotation}deg) translateZ(1px);
      transition: ${theme.transitions.smoothWithBounceSlower};
  `,
  right: (hRotation: number, vRotation: number) => css`
      background-color: ${theme.colors.background};
      border: solid 1px ${theme.colors.backZ};
      color: white;
      font-size: 30px;
      transform: rotateY(${hRotation}deg) rotateX(${vRotation}deg) translateZ(0px);
      transition: ${theme.transitions.smoothWithBounceSlower};
  `,
  logo: css`
      font-size: 100px;
      .backZ {
          :after {
              transform: translateX(-50px) translateY(-6px);
          }
      }
  `,

};

export interface CarouselItem {
  text: string,
  element: ReactNode,
}

interface Props {
  items?: CarouselItem[],
}

const CONTROLS = {
  increment: 1,
  decrement: -1,
};

const Carousel: React.FC<Props> = ({items = []}) => {

  const [index, setIndex] = useState(0);
  const [cardFlipped, setCardFlipped] = useState(false);
  const [direction, setDirection] = useState(0);
  const [hRotation, setHRotation] = useState(0);
  const vRotation = 0;

  const handleControlClick = (clickDirection: number) => {
    setDirection(clickDirection);
    setCardFlipped(true);
    setHRotation(hRotation + (180 * clickDirection));
    // setIsMounted(false);

  };

  useEffect(() => {
    if (cardFlipped) {
      let newIndex = index + direction;
      const itemCount = items.length;
      if (newIndex > itemCount - 1) {
        newIndex = 0;
      } else if (newIndex < 0) {
        newIndex = itemCount - 1;
      }
      setIndex(newIndex);
      setCardFlipped(false);
      setHRotation((hRotation + (180 * direction)));
    }
  }, [index, cardFlipped, hRotation, direction, items.length]);


  return (
    <div css={styles.root}>
      <div css={styles.carousel}>
        <div css={styles.buttonContainer}>
          <LeftChevronIcon onClick={() => handleControlClick(CONTROLS.decrement)} css={styles.icon}/>
        </div>
        <div css={styles.content}>
          <div css={styles.stage}>
            <div css={styles.extrusion}>
              <div css={[styles.plane, styles.left(hRotation + 180, -vRotation)]}>
                <ZLogo css={styles.logo}/>
              </div>
              <div css={[styles.plane, styles.right(hRotation, vRotation)]}>
                <span css={styles.subtitle}></span>
                {items[index].element}
                <span css={styles.subtitle}>{items[index].text}</span>
              </div>
            </div>
          </div>
        </div>
        <RightChevronIcon onClick={() => handleControlClick(CONTROLS.increment)} css={styles.icon}/>
      </div>

    </div>
  );
};
export default Carousel;