/** @jsxImportSource @emotion/react */                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                //include this in all jsx files
import React from 'react';
import {css, ThemeProvider} from '@emotion/react';
import {Route, Routes} from 'react-router';
import {BrowserRouter} from 'react-router-dom';
import {theme} from './theme';
import Home from './Home';
import Drawer from './Drawer';
import {routes} from './Routes';
import About from './About';
import WeirdStuff from './WeirdStuff';
import CKS from './CKS';

const styles = {
  root: css`
      width: 100%;
      height: 100vh;
      overflow-x: hidden;
      background-color: ${theme.colors.background};
      display: flex;
      flex-direction: column;
      gap: 10px;
      position: relative;
      font-family: Roboto, sans-serif;
     * {
         user-select: none;
     }
      @media (min-width: ${theme.breakpoints.sm}px) {
          flex-direction: row;
      }
  `,

  mobileHeaderSpacer: css`
      height: 56px;
      width: 100%;
      display: flex;
      @media (min-width: ${theme.breakpoints.sm}px) {
          display: none;
      }
  `,
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <div css={styles.root}>

        <div css={styles.mobileHeaderSpacer}/>
        <BrowserRouter>
          <Drawer/>
          <Routes>
            <Route path={routes.home} element={<Home/>}/>
            <Route path={routes.aboutMe} element={<About/>}/>
            <Route path={routes.currentProject} element={<CKS/>}/>
            <Route path={routes.weirdStuff} element={<WeirdStuff />}/>
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
};

export default App;
