/** @jsxImportSource @emotion/react */  //include this in all jsx files
import React, {useEffect, useRef, useState} from 'react';
import {css} from '@emotion/react'

const styles = {
    sliderRoot: css`
        position: relative;
        touch-action: none;

        &:active {
            & > .shadow {
                transform: translateY(-5px) translateX(-12px);
                transition: transform 34ms;
            }

        ,
        }
    `,
    root: css`
        justify-content: center;
        width: 200px;
        height: 10px;
        padding: 20px;
        border-radius: 10px;
        touch-action: none;
    `,
    bar: css`
        height: 6px;
        border-radius: 50px;
        width: 200px;
        background-color: #85AABF;
        position: relative;
    `,
    status: location => css`
        height: 6px;
        border-radius: 50px;
        width: ${location}px;
        background-color: #0E5E8C;
        position: absolute;
        top: 0;
        left: 0;
    `,

    thumb: location => css`
        height: 20px;
        width: 30px;
        border-radius: 100px;
        left: ${location}px;
        background-color: #158AD0;
        position: absolute;
        top: 0;
        touch-action: none;
        cursor: grab;
        transform: translateY(-10px) translateX(-12px); //radius of circle minus half the bar height
        transition: transform 400ms cubic-bezier(.3, .7, .4, 1);

        &:hover {
            transform: translateY(-12px) translateX(-12px);
            transition: transform 250ms;
        }

        &:active {
            cursor: grabbing;
            transform: translateY(-8px) translateX(-12px);
            transition: transform 34ms;
        }
    `,
    thumbEdge: location => css`
        height: 20px;
        width: 30px;
        border-radius: 100px;
        left: ${location}px;
        background-color: #0E5E8C;
        position: absolute;
        top: 0;
        cursor: grab;
        transform: translateY(-7px) translateX(-12px); //radius of circle minus half the bar height

    `,
    shadow: location => css`
        height: 20px;
        width: 30px;
        border-radius: 100px;
        left: ${location}px;
        background: hsl(0deg 0% 0% / 0.25);
        position: absolute;
        will-change: transform;
        top: 0;
        cursor: grab;
        filter: blur(1px);
        transform: translateY(-4px) translateX(-12px); //radius of circle minus half the bar height
        transition: transform 400ms cubic-bezier(.3, .7, .4, 1);
    `,
}


const Slider = (props) => {
    const {initialPosition = 0} = props;
    const [position, setPosition] = useState(initialPosition);
    const [pressed, setPressed] = useState(false);
    const [slidePercent, setSlidePercent] = useState(initialPosition/200);
    const sliderRef = useRef(null);

    const onPointerMove = (event) => {
        if (!pressed) {
            return
        }
        const x = sliderRef.current.getBoundingClientRect().left;
        let pos = event.clientX - x;
        pos >= 0 && pos <= 200 && setPosition(pos);
        setSlidePercent(position / 200);
        props.onChange && props.onChange(slidePercent);
        event.stopPropagation();
        event.preventDefault();
    }

    const onPointerUp = (event) => {

        setPressed(false);
        event.target.releasePointerCapture(event.pointerId);
        event.stopPropagation();
        event.preventDefault();
    }

    const onPointerDown = (event) => {
        setPressed(true);
        event.target.setPointerCapture(event.pointerId);
        event.stopPropagation();
        event.preventDefault();
    }

    useEffect(() => {
        if (pressed) {
            document.addEventListener("pointerup", onPointerUp);
        } else {
            document.removeEventListener("pointerup", onPointerUp);
        }
        return () => {
            document.removeEventListener("pointerup", onPointerUp);
        };
    }, [pressed]);

    return (
        <div className={props.className} css={styles.root} onPointerMove={onPointerMove}>
            <div ref={sliderRef} css={styles.sliderRoot}>
                <div css={styles.bar}/>
                <div css={styles.status(position)}/>
                <div id="sliderShadow" className="shadow" css={styles.shadow(position)}/>
                <div className="thumbEdge" css={styles.thumbEdge(position)}/>
                <div className="thumb" onPointerDown={onPointerDown} css={styles.thumb(position)}/>
            </div>
        </div>
    )
}

export default Slider;