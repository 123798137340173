/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
// import ButtonAnalog from "./Depricated/Buttons/ButtonAnalog";
import React from 'react';
import {useNavigate} from 'react-router';
import {theme} from './theme';
import {routes} from './Routes';


const styles = {
  content: css`
      display: flex;
      flex-direction: column;
      padding: 10px;
      position: relative;
      font-size: 16px;
      color: #fff;
      width: 100%;

      span {
          font-family: "Arial", sans-serif;
      }

      strong {
          color: ${theme.colors.backZ}
      }
  `,
  headerText: css`
      color: #fff;
      font-size: 20px;
  `,
  header: css`
      display: flex;
      height: 40px;
      width: 100%;
  `,
  buttonContainer: css`
      background: linear-gradient(to right, ${theme.colors.backZ} 4px, transparent 4px) 0 0,
      linear-gradient(to right, ${theme.colors.backZ} 4px, transparent 4px) 0 100%,
      linear-gradient(to left, ${theme.colors.backZ} 4px, transparent 4px) 100% 0,
      linear-gradient(to left, ${theme.colors.backZ} 4px, transparent 4px) 100% 100%,
      linear-gradient(to bottom, ${theme.colors.backZ} 4px, transparent 4px) 0 0,
      linear-gradient(to bottom, ${theme.colors.backZ} 4px, transparent 4px) 100% 0,
      linear-gradient(to top, ${theme.colors.backZ} 4px, transparent 4px) 0 100%,
      linear-gradient(to top, ${theme.colors.backZ} 4px, transparent 4px) 100% 100%;
      background-repeat: no-repeat;
      background-size: 20px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
      height: 30px;
      margin-top: 20px;
      color: ${theme.colors.frontZ};
      cursor: pointer;
      transition: ${theme.transitions.smoothFast};
      align-self: center;
      outline: none;
      border: none;

      :hover {
          font-size: 14px;
      }
  `,
  link: css`
      text-decoration: underline;
      color: ${theme.colors.frontZ};
      cursor: pointer;
      background-color: transparent;
      outline: none;
      border: none;
      font-family: "Roboto Thin", sans-serif;
      font-size: 16px;
  `,
  blur: css`
      color: ${theme.colors.backZ};

  `,
};


const About: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div css={styles.content}>
      <div css={styles.header}>
        <span css={styles.headerText}>About Me</span>
      </div>
      <span>Solving problems is my thing, and I'm passionate about finding elegant solutions to complex problems.</span>
      <br/>
      <span>Though I started my career in Mechanical Engineering, I found a love for software development while creating <button
        css={styles.link} onClick={() => navigate(routes.currentProject)}>Champions Keep Score</button>, and have been passionate about Front-End Development since.
      </span>
      <br/>
      <span>I am an equal mix of <strong>creative</strong> & <strong>technical</strong>, and thrive within a diverse team.</span>
      <br/>
      <span>I love pushing the boundaries in order to hone my craft. I've created a library of components that have never found a home in a commercial setting, but are fun and deserve to be enjoyed. Head over to <button
        css={styles.link} onClick={() => navigate(routes.weirdStuff)}>Weird Stuff</button> to check out what I mean, and I hope you enjoy!</span>
      <br/>
      <span>If you're looking for some of my professional history and a list of skills, here is my resume 👇</span>
      <br/>
      <br/>


      <a href="/resume.pdf" download css={styles.buttonContainer}>
        Download Resume
      </a>
    </div>
  );
};
export default About;