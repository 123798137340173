/** @jsxImportSource @emotion/react */ //include this in all jsx files
import React from 'react';
import { css } from '@emotion/react'

//This component requires a color prop
const styles = {
    wrapper: css`
        position: relative;
        padding: 3px 0 0 0;
        background: transparent;
        border: none;
        cursor: pointer;
        touch-action: none;

        &:hover {
            & > .top {
                transform: translatey(-8px);
                transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
            }

            & > .shadow {
                transform: translatey(4px);
                transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
            }
        }

        &:active {
            & > .top {
                transform: translatey(-2px);
                transition: transform 34ms;
            }

            & > .shadow {
                transform: translatey(1px);
                transition: transform 34ms;
            }
        }
    `,
    top: color => css`
      padding: 15px 40px;
      position: relative;
      top: 0;
      left: 0;
      border-radius: 12px;
      background-color: ${color};
      display: block;
      font-size: 1.25em;
      color: #fff;
      font-weight: 700;
      transform: translateY(-6px);
      will-change: transform;
      transition:
              transform
              600ms
              cubic-bezier(.3, .7, .4, 1);
    `,
    bottom: color => css`
      width: 100%;
      height: 100%;
      position: absolute;
      background: ${color};
      top: 0;
      left: 0;
      border-radius: 12px;
    `,
    detail: css`
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 12px;
      background: linear-gradient(
            to left,
            hsla(0, 0%, 0%, .5) 0%,
            hsla(0, 0%, 0%, .3) 8%,
            hsla(0, 0%, 0%, .3) 92%,
            hsla(0, 0%, 0%, .5) 100%
    );
    `,
    shadow: css`
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      transform: translateY(3px);
      border-radius: 12px;
      background: hsl(0deg 0% 0% / 0.25);
      will-change: transform;
      filter: blur(1px);
      transition:
              transform
              600ms
              cubic-bezier(.3, .7, .4, 1);
    `,

}

//checks if color string is valid and there
const hasColor = (col) => {
    const s = new Option().style;
    s.color = col;
    return s.color !== '';
}

const ButtonAnalog = (props) => {

    const buttonColor = !hasColor(props.color) ? "blue" : props.color;
    return(
        <button className={props.className} onPointerUp={props.onClick}  css={styles.wrapper}>
            <div className="shadow" css={styles.shadow} />
            <div css={styles.bottom(buttonColor)} />
            <div css={styles.detail}/>
            <div className="top" css={styles.top(buttonColor)}>
                <span>{props.children}</span>
            </div>
        </button>
    )
}

export default ButtonAnalog;