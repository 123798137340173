/** @jsxImportSource @emotion/react */ //include this in all jsx files
import React, {useState, useRef, useEffect} from 'react';
import { css } from '@emotion/react'
import {openSimplexNoise} from "./openSimplexNoise";
import {theme} from "../theme";

const styles = {
    root: css`
        justify-content: center;
        align-items: center;
        touch-action: none;
        display: flex;
    `,
    canvas: css`
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
    `,
    noCanvas: css`
      position: absolute;
      height: 1px;
      width: 1px;
      top: 0;
      left: 0;
      
    `,

};

let animationId;


//from: https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        windowWidth: width,
        windowHeight: height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}
function hueToRgb(v1, v2, vh) {
    vh /= 360;
    if (vh < 0) vh++;
    if (vh > 1) vh--;
    if (vh < 1 / 6) return v1 + (v2 - v1) * 6 * vh;
    if (vh < 1 / 2) return v2;
    if (vh < 2 / 3) return v1 + (v2 - v1) * (2 / 3 - vh) * 6;
    return v1;
}
function hslToRgb(h, s, l) {
    s /= 100;
    l /= 100;

    let r, g, b;

    if(s === 0){
        r = g = b = l * 255;
    } else {
        const v2 = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const v1 = 2 * l - v2;
        r = Math.round(hueToRgb(v1, v2, h + 120) * 255);
        g = Math.round(hueToRgb(v1, v2, h) * 255);
        b = Math.round(hueToRgb(v1, v2, h - 120) * 255);
    }
    return "rgb("+r+", "+g+", "+b+")";
}



//https://stackoverflow.com/questions/7054272/how-to-draw-smooth-curve-through-n-points-using-javascript-html5-canvas
function getCurvePoints(pts, tension, isClosed, numOfSegments) {

    // use input value if provided, or use a default value
    tension = (typeof tension != 'undefined') ? tension : 0.5;
    isClosed = isClosed ? isClosed : false;
    numOfSegments = numOfSegments ? numOfSegments : 16;

    let _pts = [], res = [],    // clone array
        x, y,           // our x,y coords
        t1x, t2x, t1y, t2y, // tension vectors
        c1, c2, c3, c4,     // cardinal points
        st, t, i;       // steps based on num. of segments

    // clone array so we don't change the original
    //
    _pts = pts.slice(0);

    // The algorithm require a previous and next point to the actual point array.
    // Check if we will draw closed or open curve.
    // If closed, copy end points to beginning and first points to end
    // If open, duplicate first points to beginning, end points to end
    if (isClosed) {
        _pts.unshift(pts[pts.length - 1]);
        _pts.unshift(pts[pts.length - 2]);
        _pts.unshift(pts[pts.length - 1]);
        _pts.unshift(pts[pts.length - 2]);
        _pts.push(pts[0]);
        _pts.push(pts[1]);
    }
    else {
        _pts.unshift(pts[1]);   //copy 1. point and insert at beginning
        _pts.unshift(pts[0]);
        _pts.push(pts[pts.length - 2]); //copy last point and append
        _pts.push(pts[pts.length - 1]);
    }

    // ok, lets start..

    // 1. loop goes through point array
    // 2. loop goes through each segment between the 2 pts + 1e point before and after
    for (i=2; i < (_pts.length - 4); i+=2) {
        for (t=0; t <= numOfSegments; t++) {

            // calc tension vectors
            t1x = (_pts[i+2] - _pts[i-2]) * tension;
            t2x = (_pts[i+4] - _pts[i]) * tension;

            t1y = (_pts[i+3] - _pts[i-1]) * tension;
            t2y = (_pts[i+5] - _pts[i+1]) * tension;

            // calc step
            st = t / numOfSegments;

            // calc cardinals
            c1 =   2 * Math.pow(st, 3)  - 3 * Math.pow(st, 2) + 1;
            c2 = -(2 * Math.pow(st, 3)) + 3 * Math.pow(st, 2);
            c3 =       Math.pow(st, 3)  - 2 * Math.pow(st, 2) + st;
            c4 =       Math.pow(st, 3)  -     Math.pow(st, 2);

            // calc x and y cords with common control vectors
            x = c1 * _pts[i]    + c2 * _pts[i+2] + c3 * t1x + c4 * t2x;
            y = c1 * _pts[i+1]  + c2 * _pts[i+3] + c3 * t1y + c4 * t2y;

            //store points in array
            res.push(x);
            res.push(y);
        }
    }
    return res;
}

function drawCurve(ctx, ptsa, tension, isClosed, numOfSegments, showPoints) {

    showPoints  = showPoints ? showPoints : false;

    drawLines(ctx, getCurvePoints(ptsa, tension, isClosed, numOfSegments));

    if (showPoints) {
        ctx.stroke();
        ctx.beginPath();
        for(let i=0;i<ptsa.length-1;i+=2)
            ctx.rect(ptsa[i] - 2, ptsa[i+1] - 2, 4, 4);
    }
}

function drawLines(ctx, pts) {
    ctx.moveTo(pts[0], pts[1]);
    for(let i=2;i<pts.length-1;i+=2) ctx.lineTo(pts[i], pts[i+1]);
}


const noiseSeed = Date.now();
const noise = openSimplexNoise(noiseSeed);

function drawImpact(context, pts, flicker, color) {
    context.beginPath();
    context.fillStyle = color;
    context.shadowBlur = 10;
    context.shadowColor = color;
    context.arc(pts[pts.length-2], pts[pts.length-1], noise.noise2D(flicker, .5)*10+10,0, 2*Math.PI);
    context.fill();

}

const cancelStrike = (canvas, context) => {
    context.clearRect(0, 0, canvas.width, canvas.height);
}

const renderStrike = (canvas, context, windowWidth, windowHeight, mouseXY, clickedZap) => {

    let time = Math.floor(Math.random()*1000);
    let flicker = 0;
    const amplitude = 50;
    const H = 195;
    const S = 100;
    const L_MAX = 75;
    const L_MIN = 55;

    const x0 = mouseXY.x;

    const render = () => {
        if (!clickedZap) {
            return
        }

        const colorL = (noise.noise2D(flicker, 0))*(L_MAX-L_MIN)+ (L_MAX + L_MIN)/2;
        const colorLChild1 = (noise.noise2D(flicker+.30, 0))*(L_MAX-L_MIN)+ (L_MAX + L_MIN)/2;
        const colorLChild2 =(noise.noise2D(flicker+45, 0))*(L_MAX-L_MIN)+ (L_MAX + L_MIN)/2;

        const getCoordinates = (time, segments) => {
            const yStep = mouseXY.y/(segments-1);
            const pts = []

            pts[0] = x0;
            pts[1] = 0;

            for(let i=1; i<segments; i++){
                const lowOctaveNoise = noise.noise2D(time+i/8, 0)*amplitude*3;
                const highOctaveNoise = noise.noise2D(time+i*180, 0)*amplitude/1.5;
                pts[i*2] = x0 + lowOctaveNoise + highOctaveNoise;
                pts[i*2+1] = i*yStep
            }
            pts[segments*2-2] = mouseXY.x;
            pts[segments*2-1] = mouseXY.y;

            return pts;
        }
        const getChildCoordinates = (time, offset, segments) => {

            let pts = getCoordinates(time, segments);
            for(let i=4;i<pts.length-4;i+=2){
                pts[i] = pts[i] + noise.noise2D((time+offset+i)*10, 0)*amplitude/1.5;
            }
            return pts;
        }

        context.clearRect(0, 0, canvas.width, canvas.height);

        context.beginPath();

        context.beginPath();
        context.lineWidth = 3;
        context.strokeStyle = hslToRgb(H, S, colorLChild1);

        drawCurve(context, getChildCoordinates(time, .25, 8),1, false, 15, false);
        context.stroke();
        context.beginPath();
        context.lineWidth = 3;
        context.strokeStyle = hslToRgb(H, S, colorLChild2);
        drawCurve(context, getChildCoordinates(time, 20, 8),1, false, 15, false);
        context.stroke();
        context.beginPath();
        context.lineWidth = 7;
        context.strokeStyle = hslToRgb(H, S, colorL);
        context.shadowBlur = 50;
        context.shadowColor = hslToRgb(H, S, colorL);
        drawCurve(context, getCoordinates(time, 8),.5, false, 8, false);
        context.stroke();
        context.lineCap = 'round';
        drawImpact(context, getCoordinates(time, 8), flicker, hslToRgb(H, S, colorL));

        time += .005;
        flicker += .15;

        animationId = requestAnimationFrame(render);
    }
    render();


}
const cancelRender = () => {
    cancelAnimationFrame(animationId);
}
const LightningStrike= (props) => {
    // const { windowHeight, windowWidth } = useWindowDimensions();
    const {  windowWidth:actualWindowWidth, windowHeight: actualWindowHeight } = useWindowDimensions();
    const windowHeight = actualWindowHeight;
    const windowWidth = actualWindowWidth > theme.breakpoints.sm ?  500 : actualWindowWidth;
    const [mouseXY, setMouseXY] = useState({x:0, y:0});
    const [clickedZap, setClickedZap] = useState(false);
    const canvasRef = useRef(null);


    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        canvas.width = windowWidth > theme.breakpoints.sm ?  500 : windowWidth ;
        canvas.height = 500;
        clickedZap ? renderStrike(canvas, context, windowWidth, windowHeight, mouseXY, clickedZap) : cancelStrike(canvas, context);
        return () => {
            cancelRender(canvas,context);
        }
    }, [clickedZap, mouseXY, windowHeight, windowWidth])

    const handleClick = (event) => {
        clickedZap === true ? setClickedZap(false): setClickedZap(true);
        const rect = event.target.getBoundingClientRect();
        setMouseXY({x:event.clientX  + (windowWidth > theme.breakpoints.sm ? 0  : 250 - (rect.right)), y:event.clientY + rect.top});
        event.target.setPointerCapture(event.pointerId);
    }
    const handleMouseUp = () => {
        setClickedZap(false);
    }

    useEffect(() => {
        if (clickedZap) {
            document.addEventListener("pointerup", handleMouseUp);
        } else {
            document.removeEventListener("pointerup", handleMouseUp);
        }
        return () => {
            document.removeEventListener("pointerup", handleMouseUp);
        };
    }, [clickedZap]);
    return(
        <div onPointerUp={handleMouseUp} onPointerDown={handleClick} css={styles.root}>
            <canvas ref={canvasRef} css={clickedZap?styles.canvas:styles.noCanvas} />
            {props.children}
        </div>
        )
    }

export default LightningStrike;