/** @jsxImportSource @emotion/react */                                //include this in all jsx files
import React, {useEffect, useState} from 'react';
import {css} from '@emotion/react'
import Slider from "../Slider";
import ZLogo from "../../ZLogo";

const styles = {
    root: css`
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
    `,
    stage: css`
        perspective: 1000px;
        padding: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
    `,
    extrusion: css`
        width: 200px;
        height: 200px;
        position: relative;
        transform-style: preserve-3d;
        transition: transform 1s;
    `,
    plane: css`
        position: absolute;
        width: 100%;
        height: 200px;
        border-radius: 20px;
        text-align: center;
        border: solid 1px #4e6468;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        span {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    `,
    left: (hRotation, vRotation) => css`
        background-color: #304145;
        color: white;
        font-size: 30px;
        transform: rotateY(${hRotation}deg) rotateX(${vRotation}deg) translateZ(1px);
    `,
    right: (hRotation, vRotation) => css`
        background-color: #304145;
        color: white;
        font-size: 30px;
        transform: rotateY(${hRotation}deg) rotateX(${vRotation}deg) translateZ(0px);
    `,
    slider: css`
        border: none;
        background-color: #304145;
    `,
    horizontalSlider: css`
        border: none;
        background-color: #304145;
        display: flex;
        align-self: center;
    `,
};


const IntroCard = () => {
    const [hRotation, setHRotation] = useState(0);
    const vRotation = 0;
    const [showGreeting, setShowGreeting] = useState(false);
    const [showLastGreeting, setShowLastGreeting] = useState(false);
    const [resetMessage, setResetMessage] = useState(false);

    const handleChangeHorizontal = (percent) => {
        setHRotation(percent * 180);
    };


    useEffect(() => {
        if (hRotation < 80 && resetMessage) {
            setShowGreeting(false)
            setShowLastGreeting(false)
            setResetMessage(false)
        } else if (hRotation > 150 && !showGreeting && !showLastGreeting && !resetMessage) {

            setShowGreeting(true)
        } else if (hRotation < 80 && showGreeting && !showLastGreeting && !resetMessage) {

            setShowLastGreeting(true);
        } else if (hRotation > 150 && showGreeting && showLastGreeting && !resetMessage) {

            setResetMessage(true)
        }
    }, [hRotation, showGreeting, showLastGreeting, resetMessage])



    return (
        <div css={styles.root}>
            <div css={styles.stage}>
                <div css={styles.extrusion}>
                    <div css={[styles.plane, styles.left(hRotation + 180, -vRotation)]}>
                        {!showLastGreeting ?
                            <span>
                                I'm <ZLogo/>ack.
                            </span>
                            :
                            <span>Ok, bye.</span>
                        }
                    </div>
                    <div css={[styles.plane, styles.right(hRotation, vRotation)]}>
                        {!showGreeting ?
                            <span>Hi.</span>
                            :
                            <span>Nice to meet you.</span>
                        }
                    </div>
                </div>
            </div>
            <Slider onChange={handleChangeHorizontal}/>
        </div>
    )
};

export default IntroCard;