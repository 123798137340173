/** @jsxImportSource @emotion/react */ //include this in all jsx files
import React, {useState, useRef, useEffect} from 'react';
import { css } from '@emotion/react'
import {theme} from "../theme";

const styles = {
    root: css`
        justify-content: center;
        align-items: center;
        width: 100%;
        display: flex;
        overflow: hidden;
        touch-action: none;
    `,
    canvas: (border) => css`
    border: ${border? "solid 1px lightgray" : "none"};
    `,
};

const elementLength = (distance, maxLength, influenceRadius) => {
    const minLength = 0;
    const offsetLength = ((maxLength*influenceRadius) - distance) * .1; //affects the radius of influence and speed of growth
    const testLength = minLength > offsetLength ? minLength : offsetLength;
    const Length = testLength < maxLength ? testLength : maxLength;
    return(
        Length
    )};

const MagneticBox= (props) => {
    const [mouseXY, setMouseXY] = useState({x:100000, y:100000});
    const canvasRef = useRef(null);


    useEffect(() => {

        const canvasWidth = props.canvasWidth ? props.canvasWidth : props.columnCount * props.columnOffset ?
            props.columnCount * props.columnOffset : 500;
        const canvasHeight =  props.canvasHeight ? props.canvasHeight : props.rowCount * props.rowOffset ?
            props.rowCount*props.rowOffset : 300;

        let canvas = canvasRef.current;
        canvas.width = canvasWidth;
        canvas.height= canvasHeight;
        let context = canvas.getContext('2d');
        let requestId;

        function element(originX, originY, noFill, fill, x, y, height, maxLength, fade, influenceRadius) {
            const distance = Math.sqrt(Math.pow(x-originX, 2) + Math.pow(y-originY, 2));
            const opacity = !fade ? 1 : elementLength(distance, maxLength, influenceRadius) / maxLength;
            context.fillStyle = elementLength(distance, maxLength, influenceRadius) ===0 ? noFill : fill;
            context.save();
            context.beginPath();
            context.translate(originX,originY);
            context.rotate(Math.atan2((y-originY), (x-originX)));
            context.arc(
                elementLength(distance, maxLength, influenceRadius),
                0,
                1* height,
                1.5* Math.PI,
                .5* Math.PI,
                false,
            );
            context.arc(
                -elementLength(distance, maxLength, influenceRadius),
                0,
                1*height,
                .5*Math.PI,
                1.5*Math.PI,
                false,
            );
            context.closePath();
            context.globalAlpha = opacity;
            context.fill();
            context.restore();
        }

        function elementArray(rowCount ,columnCount, rowOffset, columnOffset,noFill, fill, mouseX, mouseY, height, maxLength, fade, influenceRadius) {
            for (let xx = 0; xx < columnCount; xx++) {
                for (let yy = 0; yy < rowCount; yy++) {
                    element(xx*columnOffset+columnOffset/2, yy*rowOffset+rowOffset/2, noFill, fill, mouseX, mouseY, height, maxLength, fade, influenceRadius );
                }
            }
        }

        const render = () => {
            const x =  mouseXY.x-canvas.offsetLeft;
            const y =  mouseXY.y; //mouse position relative to top left corner of canvas

            context.clearRect(0, 0, canvas.width, canvas.height);
            elementArray(
                props.rowCount ? props.rowCount : 20, //rows in field
                props.columnCount ? props.columnCount : 20, //columns in field
                props.rowOffset? props.rowOffset : 30, //distance between rows
                props.columnOffset? props.columnOffset : 30, //distance between columns
                props.noFill? props.noFill : (props.fill? props.fill: theme.colors.backZ), //color of unaffected elements
                props.fill? props.fill : theme.colors.frontZ, //color of affected elements
                x, //calculated
                y, //calculated
                props.height? props.height: 2, //thickness of element
                props.maxLength? props.maxLength: 5,//maximum length of element
                props.fade? props.fade: false,  //true if elements fade in as affected
                props.influenceRadius? props.influenceRadius: 25); //how close mouse must be to element to affect it


            requestId = requestAnimationFrame(render);
        };
        render();

        return () => {
            cancelAnimationFrame(requestId);
        }
    }, [
        mouseXY,
        props.rowCount,
        props.columnCount,
        props.rowOffset,
        props.columnOffset,
        props.height,
        props.maxLength,
        props.fade,
        props.noFill,
        props.fill,
        props.canvasWidth,
        props.canvasHeight,
        props.influenceRadius,
        canvasRef,
    ]);

    const handleMouseMove = (event) => {
        let currentTarget = event.currentTarget.getBoundingClientRect();
        setMouseXY({x:event.pageX-currentTarget.left, y:event.pageY - currentTarget.top});
    };
    const handleMouseLeave = () => {
        setMouseXY({x:10000, y:100000});
    };

    const handlePointerMove = (event) => {
        console.log(event);
        let currentTarget = event.currentTarget.getBoundingClientRect();
        setMouseXY({x:event.pageX-currentTarget.left, y:event.pageY - currentTarget.top});
    }

    return(
        <div onPointerMove={handlePointerMove} onMouseUp={handleMouseLeave} onPointerDown={handleMouseMove} onPointerUp={handleMouseLeave} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave} css={styles.root} >
            <canvas  ref={canvasRef} css={styles.canvas(props.border)} />
        </div>
        )
    }

export default MagneticBox;