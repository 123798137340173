/** @jsxImportSource @emotion/react */ //include this in all jsx files
import React, {useState} from 'react';
import {css} from '@emotion/react'
import Slider from "../Slider";
import VerticalSlider from "../VerticalSlider";
import {theme} from "../../theme";

const styles = {
    root: css`
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
    `,
    stage: css`
        perspective: 1000px;
        padding: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;
    `,
    extrusion: css`
        width: 200px;
        height: 200px;
        position: relative;
        transform-style: preserve-3d;
        transform: translateZ(-100px);
        transition: transform 1s;
        padding: 50px;

    `,
    plane: css`
        position: absolute;
        width: 150px;
        height: 150px;
        border-radius: 20px;
    `,
    button: css`
        position: absolute;
        border-radius: 20px;
        top: 124px;
        left: 96px;
    `,
    slider: css`
        margin-top: 10px;
    `,
    buttonReverse: css`
        transform: rotateY(180deg);
    `,
    left: (hRotation, vRotation) => css`
        background-color: ${theme.colors.frontZ};
        transform: rotateY(${hRotation}deg) rotateX(${vRotation}deg) translateZ(-50px);
    `,
    right: (hRotation, vRotation) => css`
        background-color: ${theme.colors.backZ};
        transform: rotateY(${hRotation}deg) rotateX(${vRotation}deg) translateZ(50px);
    `,
    buttonLeft: css`
        transform: rotateY(270deg) translateX(-100px);
        transform-origin: center left;
        position: absolute;
        width: 200px;
        height: 200px;
        background-color: ${theme.colors.frontZ};
        border: 1px solid #000;
    `,
    buttonTop: css`
        transform: rotateX(-90deg) translateY(-100px);
        transform-origin: top center;
        position: absolute;
        width: 200px;
        height: 200px;
        background-color: ${theme.colors.frontZ};
        border: 1px solid #000;
    `,
    buttonBottom: css`
        transform: rotateX(90deg) translateY(100px);
        transform-origin: bottom center;
        position: absolute;
        width: 200px;
        height: 200px;
        background-color: ${theme.colors.frontZ};
        border: 1px solid #000;
    `,
    box: css`
        position: relative;
        width: 200px;
        height: 200px;
        perspective: 1000px;
        perspective-origin: 50% 10%;
    `,
};


const RotationShapes = () => {
    const [hRotation, setHRotation] = useState(0);
    const [vRotation, setVRotation] = useState(0);

    const handleChangeHorizontal = (percent) => {
        console.log(percent);
        setHRotation(percent * 360 + 180);
    };
    const handleChangeVertical = (percent) => {
        setVRotation(percent * 360 + 180);
    };

    return (
        <div css={styles.root}>
            <div css={styles.stage}>
                <div css={styles.extrusion}>
                    <div css={[styles.plane, styles.left(hRotation, vRotation)]}/>
                    <div css={[styles.plane, styles.right(hRotation, vRotation)]}/>
                </div>
                <VerticalSlider initialPosition={100} onChange={handleChangeVertical}/>
            </div>
            <Slider initialPosition={100}  onChange={handleChangeHorizontal}/>
        </div>
    );
};

export default RotationShapes